import { ActionProps } from '~/components/Actions/props';
import IconCartShopping from '~/icons/CartShopping';
import IconFileInvoice from '~/icons/FileInvoice';

const useOrders = ({
  espaceId,
  pathname,
}: {
  espaceId: string;
  pathname: string;
}): (ActionProps | ActionProps[])[] | undefined => {
  const isActive = (to: string, strict?: boolean): string | undefined => {
    if (strict) {
      return `/espaces/${espaceId}/orders${to}` === pathname
        ? 'is-active'
        : undefined;
    }

    return pathname.slice(0, `/espaces/${espaceId}/orders${to}`.length) ===
      `/espaces/${espaceId}/orders${to}`
      ? 'is-active'
      : undefined;
  };

  return [
    {
      className: isActive(``, true),
      icon: IconFileInvoice,
      label: 'Bons de commande',
      to: `/espaces/${espaceId}/orders`,
    },
    {
      className: isActive(`/carts`),
      icon: IconCartShopping,
      label: 'Panier',
      to: `/espaces/${espaceId}/orders/carts`,
    },
  ];
};

export default useOrders;
