import { CartType, DocumentType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import compact from 'lodash/compact';
import { CartData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '~/containers/Espace/List/props';

import ListItem from './index';

const ListItemCart: FC<ListItemProps<CartType, CartData>> = ({
  className,
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] = useState<DocumentType<CartType>>();

  useEffect(() => {
    let isMounted = true;
    const unsub = model.watchById(docId, document => {
      if (isMounted) {
        setDoc(document);
      }
    });

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  return (
    <ListItem
      className={className}
      description={
        doc.items
          ? Object.keys(doc.items)
              .map(key => {
                if (!doc.items || !doc.items[key]) {
                  return '';
                }

                const { label } = doc.items[key].data;
                const parent = doc.items[key].parent?.label;

                return compact([parent, label]).join(' : ');
              })
              .join('\n')
          : ''
      }
      displayActionId={false}
      doc={doc}
      index={index}
      label={dayjs(doc.createdAt).format('dddd D MMMM YYYY à HH:mm')}
      onClick={onClick}
      // subLabel={doc.items?.length}
      subLabel={compact([doc.status, doc.phone]).join(' - ')}
    />
  );
};

export default ListItemCart;
