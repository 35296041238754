import { PageProps } from 'gatsby';
import { CartData } from 'packages/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '~/components/List/Item/Cart';
import CMSView from '~/components/View';
import List from '~/containers/Espace/List';
import cart from '~/params/cart.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';
import useOrders from '~/utils/useOrders';

const PageCarts: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  location,
  user,
  params: { espaceId },
}) => {
  const { t } = useTranslation();
  const items = useOrders({
    espaceId: espace.id,
    pathname: location.pathname,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <List
          itemList={Item}
          model={
            new CartData({
              espaceId,
              params: cart,
            })
          }
          removeAddButton
          search={location.search}
          subMenu={items}
          title={t('bons-commande.title')}
          user={user}
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageCarts);
